import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/workspace/src/components/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/ImageAndTextBlock/ImageAndTextBlock.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/ImageGalleryBlock/ImageGalleryBlock.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/ImageLinkBlock/ImageLinkBlock.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/InclusivelyHidden/InclusivelyHidden.module.css");
import(/* webpackMode: "eager" */ "/workspace/src/components/ProjectGallery/ProjectGallery.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/Prose/Prose.module.css");
import(/* webpackMode: "eager" */ "/workspace/src/components/Blocks/Blocks.module.css");
import(/* webpackMode: "eager" */ "/workspace/src/components/ProjectAwards/ProjectAwards.module.css");
import(/* webpackMode: "eager" */ "/workspace/src/components/ProjectDownloadsBlock/ProjectDownloadsBlock.module.css");
import(/* webpackMode: "eager" */ "/workspace/src/components/ProjectDetailsBlock/ProjectDetailsBlock.module.css");
import(/* webpackMode: "eager" */ "/workspace/src/components/CradleToCradleBlock/CradleToCradleBlock.module.css");
import(/* webpackMode: "eager" */ "/workspace/src/components/Quote/Quote.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/SideBySideImageBlock/SideBySideImageBlock.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/StageImage/StageImage.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/StandaloneImageBlock/StandaloneImageBlock.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/TeamGallery/TeamGallery.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/components/TextComponent/TextComponent.module.css");
import(/* webpackMode: "eager" */ "/workspace/src/components/VideoBlock/VideoBlock.tsx");
import(/* webpackMode: "eager" */ "/workspace/src/LoadingContext.tsx")